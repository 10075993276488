exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-applications-application-id-applications-detail-page-tsx": () => import("./../../../src/pages/applications/[applicationId]/ApplicationsDetailPage.tsx" /* webpackChunkName: "component---src-pages-applications-application-id-applications-detail-page-tsx" */),
  "component---src-pages-applications-application-id-index-tsx": () => import("./../../../src/pages/applications/[applicationId]/index.tsx" /* webpackChunkName: "component---src-pages-applications-application-id-index-tsx" */),
  "component---src-pages-applications-applications-page-tsx": () => import("./../../../src/pages/applications/ApplicationsPage.tsx" /* webpackChunkName: "component---src-pages-applications-applications-page-tsx" */),
  "component---src-pages-applications-index-tsx": () => import("./../../../src/pages/applications/index.tsx" /* webpackChunkName: "component---src-pages-applications-index-tsx" */),
  "component---src-pages-callbacks-digid-tsx": () => import("./../../../src/pages/callbacks/digid.tsx" /* webpackChunkName: "component---src-pages-callbacks-digid-tsx" */),
  "component---src-pages-categories-categories-page-tsx": () => import("./../../../src/pages/categories/CategoriesPage.tsx" /* webpackChunkName: "component---src-pages-categories-categories-page-tsx" */),
  "component---src-pages-categories-category-id-categories-detail-page-tsx": () => import("./../../../src/pages/categories/[categoryId]/CategoriesDetailPage.tsx" /* webpackChunkName: "component---src-pages-categories-category-id-categories-detail-page-tsx" */),
  "component---src-pages-categories-category-id-index-tsx": () => import("./../../../src/pages/categories/[categoryId]/index.tsx" /* webpackChunkName: "component---src-pages-categories-category-id-index-tsx" */),
  "component---src-pages-categories-index-tsx": () => import("./../../../src/pages/categories/index.tsx" /* webpackChunkName: "component---src-pages-categories-index-tsx" */),
  "component---src-pages-components-component-id-components-detail-page-tsx": () => import("./../../../src/pages/components/[componentId]/ComponentsDetailPage.tsx" /* webpackChunkName: "component---src-pages-components-component-id-components-detail-page-tsx" */),
  "component---src-pages-components-component-id-index-tsx": () => import("./../../../src/pages/components/[componentId]/index.tsx" /* webpackChunkName: "component---src-pages-components-component-id-index-tsx" */),
  "component---src-pages-components-components-page-tsx": () => import("./../../../src/pages/components/ComponentsPage.tsx" /* webpackChunkName: "component---src-pages-components-components-page-tsx" */),
  "component---src-pages-components-index-tsx": () => import("./../../../src/pages/components/index.tsx" /* webpackChunkName: "component---src-pages-components-index-tsx" */),
  "component---src-pages-documentation-about-tsx": () => import("./../../../src/pages/documentation/about.tsx" /* webpackChunkName: "component---src-pages-documentation-about-tsx" */),
  "component---src-pages-documentation-index-tsx": () => import("./../../../src/pages/documentation/index.tsx" /* webpackChunkName: "component---src-pages-documentation-index-tsx" */),
  "component---src-pages-documentation-usage-tsx": () => import("./../../../src/pages/documentation/usage.tsx" /* webpackChunkName: "component---src-pages-documentation-usage-tsx" */),
  "component---src-pages-github-[md]-tsx": () => import("./../../../src/pages/github/[md].tsx" /* webpackChunkName: "component---src-pages-github-[md]-tsx" */),
  "component---src-pages-github-index-tsx": () => import("./../../../src/pages/github/index.tsx" /* webpackChunkName: "component---src-pages-github-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-organizations-index-tsx": () => import("./../../../src/pages/organizations/index.tsx" /* webpackChunkName: "component---src-pages-organizations-index-tsx" */),
  "component---src-pages-organizations-organization-id-index-tsx": () => import("./../../../src/pages/organizations/[organizationId]/index.tsx" /* webpackChunkName: "component---src-pages-organizations-organization-id-index-tsx" */),
  "component---src-pages-organizations-organization-id-organization-detail-page-tsx": () => import("./../../../src/pages/organizations/[organizationId]/OrganizationDetailPage.tsx" /* webpackChunkName: "component---src-pages-organizations-organization-id-organization-detail-page-tsx" */),
  "component---src-pages-organizations-organizations-page-tsx": () => import("./../../../src/pages/organizations/OrganizationsPage.tsx" /* webpackChunkName: "component---src-pages-organizations-organizations-page-tsx" */)
}

