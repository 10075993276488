// extracted by mini-css-extract-plugin
export var denhaagLinkWithIconStart = "HeaderTemplate-module--denhaag-link--with-icon-start--1685d";
export var denhaagLink__icon = "HeaderTemplate-module--denhaag-link__icon--f42bb";
export var headerContainer = "HeaderTemplate-module--headerContainer--5614f";
export var headerContent = "HeaderTemplate-module--headerContent--89682";
export var headerMiddleBar = "HeaderTemplate-module--headerMiddleBar--714eb";
export var headerSearchForm = "HeaderTemplate-module--headerSearchForm--60d86";
export var headerTopBar = "HeaderTemplate-module--headerTopBar--3a049";
export var logoContainer = "HeaderTemplate-module--logoContainer--a66d4";
export var logoDesktop = "HeaderTemplate-module--logoDesktop--3aa06";
export var logoMobile = "HeaderTemplate-module--logoMobile--bae89";
export var organizationLogo = "HeaderTemplate-module--organizationLogo--eac2a";
export var primaryNavContainer = "HeaderTemplate-module--primaryNavContainer--8e528";
export var searchFormContainer = "HeaderTemplate-module--searchFormContainer--36619";
export var secondaryNavContainer = "HeaderTemplate-module--secondaryNavContainer--f9b1c";
export var section = "HeaderTemplate-module--section--08915";
export var subHeading = "HeaderTemplate-module--subHeading--4bea0";
export var subTitle = "HeaderTemplate-module--subTitle--37f57";
export var textColor = "HeaderTemplate-module--textColor--0b6b7";
export var title = "HeaderTemplate-module--title--a2455";